
import { useEffect, useRef, useState } from 'react';
import { useStore } from '../js/Store';
import { useNavigate } from 'react-router-dom';
import Config from '../../config.json';

const makeRequest = (url, options={}, session_id=null, current=null) => {

    if (options.headers === null || options.headers === undefined) options.headers = {};

    const sessionId = session_id;
    const currentLogin = current;

    const auth = `${sessionId}.${currentLogin}`;
    options.headers['Authorization'] = `Login ${auth}`;

    return new Promise((resolve, reject) => {

        fetch(url, options)
        .then((res) => res.json())
        .then((res) => resolve(res))
        .catch((res) => reject(res));

    });

}

export const fetchIdentity = () => makeRequest(`${Config.API_ENDPOINT}/api/v1/identity`);
export const fetchSession = (session_id, current) => {
    return makeRequest(`${Config.API_ENDPOINT}/api/v1/session`, {}, session_id=session_id, current=current)
};

export const useIdentity = () => {

    const navigate = useNavigate();
    const session = useSession();

    const identity = useStore((state) => state.identity);
    const setIdentity = useStore((state) => state.setIdentity);

    useEffect(() => {

        if ((session !== null && session !== undefined) & (identity === null || identity === undefined)){

            fetchIdentity(session.id, session.current).then((res) => {

                if (res.status === 200){
                    setIdentity(res.data);
                } else {
                    window.location.href = '/login'

                }
                

            }).catch((res) => {

                
            })

        }

    }, [navigate, session, identity, setIdentity]);

    return identity;

}

export const useSession = () => {

    const navigate = useNavigate();

    const session = useStore((state) => state.session);
    const sessionId = useStore((state) => state.sessionId);
    const current = useStore((state) => state.current);
    const setSession = useStore((state) => state.setSession);
    const setAuthenticated = useStore((state) => state.setAuthenticated);

    const waitingRef = useRef(false);

    useEffect(() => {

        if (sessionId !== undefined && current !== undefined && !waitingRef.current && ((session === null || session === undefined) || (session.current === null || session.current === undefined))){

            waitingRef.current = true;

            fetchSession(sessionId, current).then((res) => {

                waitingRef.current = false;
                setAuthenticated(true);
                if (res.status === 200){
                    setSession(res.data);
                } else {
                    setSession(undefined);
                }
            });
        }
    }, [waitingRef, navigate, session, setSession, sessionId, current]);

    return session;

}