import styles from '../../assets/css/views/article.module.css';
import dialogStyles from '../../assets/css/dialog.module.css';
import { Profile } from '../../components/Profile';
import { TabView, Tab } from '../../components/TabView';
import Sidebar from '../../components/Sidebar';
import EndpointURL from '../../components/EndpointURL';
import Argument from '../../components/Argument';
import CodeExample from '../../components/CodeExample';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 as syntaxTheme } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useStore } from '../../assets/js/Store';
import { useCallback, useEffect, useState } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useParams } from 'react-router-dom';

const randomKey = () => Math.floor(Math.random() * 10000000);

const ResponseCodeBlock = ({ code, language }) => {
    return (
        <SyntaxHighlighter language={language} style={syntaxTheme} >
            {code}
        </SyntaxHighlighter>
    );
};

const consumeClassNames = (classNames) => {
    const mapped = [];
    classNames.forEach(c => mapped.push(styles[c]));
    return mapped.join(' ').trim();
}

const renderJsonElement = (element) => {
    switch (element.type) {
        case 'h1':
            return (
                <h1 {...(element.class ? {className: consumeClassNames(element.class)} : {})} key={randomKey()}>{element.text != null ? element.text : element.content.map((item, index) => renderJsonElement(item))}</h1>
            );
        case 'h2':
            return (
                <h2 {...(element.class ? {className: consumeClassNames(element.class)} : {})} {...(element.style ? {style: element.style} : {})} key={randomKey()}>{element.text != null ? element.text : element.content.map((item, index) => renderJsonElement(item))}</h2>
            );
        case 'h3':
            return (
                <h3 {...(element.class ? {className: consumeClassNames(element.class)} : {})} {...(element.style ? {style: element.style} : {})} key={randomKey()}>{element.text != null ? element.text : element.content.map((item, index) => renderJsonElement(item))}</h3>
            );
        case 'h4':
            return (
                <h4 {...(element.class ? {className: consumeClassNames(element.class)} : {})} {...(element.style ? {style: element.style} : {})} key={randomKey()}>{element.text != null ? element.text : element.content.map((item, index) => renderJsonElement(item))}</h4>
            );
        case 'h5':
            return (
                <h5 {...(element.class ? {className: consumeClassNames(element.class)} : {})} {...(element.style ? {style: element.style} : {})} key={randomKey()}>{element.text != null ? element.text : element.content.map((item, index) => renderJsonElement(item))}</h5>
            );
        case 'h6':
            return (
                <h6 {...(element.class ? {className: consumeClassNames(element.class)} : {})} {...(element.style ? {style: element.style} : {})} key={randomKey()}>{element.text != null ? element.text : element.content.map((item, index) => renderJsonElement(item))}</h6>
            );
        case 'hr':
            return (
                <hr {...(element.class ? {className: consumeClassNames(element.class)} : {})} {...(element.style ? {style: element.style} : {})} key={randomKey()} />
            );
        case 'br':
            return (
                <br {...(element.class ? {className: consumeClassNames(element.class)} : {})} {...(element.style ? {style: element.style} : {})} key={randomKey()} />
            );
        case 'p':
            return (
                <p {...(element.class ? {className: consumeClassNames(element.class)} : {})} {...(element.style ? {style: element.style} : {})} key={randomKey()}>{element.text != null ? element.text : element.content.map((item, index) => renderJsonElement(item))}</p>
            );
        case 'a':
            return (
                <a {...(element.class ? {className: consumeClassNames(element.class)} : {})} {...(element.style ? {style: element.style} : {})} key={randomKey()} {...(element.href ? { href: element.href } : {})}>{element.text != null ? element.text : element.content.map((item, index) => renderJsonElement(item))}</a>
            );
        case 'code':
            return (
                <code {...(element.class ? {className: consumeClassNames(element.class)} : {})} {...(element.style ? {style: element.style} : {})} key={randomKey()}>{element.text != null ? element.text : element.content.map((item, index) => renderJsonElement(item))}</code>
            );
        case 'span':
            return (
                <span {...(element.class ? {className: consumeClassNames(element.class)} : {})} {...(element.style ? {style: element.style} : {})} key={randomKey()}>{element.text != null ? element.text : element.content.map((item, index) => renderJsonElement(item))}</span>
            );
        case 'b':
            return (
                <b {...(element.class ? {className: consumeClassNames(element.class)} : {})} {...(element.style ? {style: element.style} : {})} key={randomKey()}>{element.text != null ? element.text : element.content.map((item, index) => renderJsonElement(item))}</b>
            );
        case 'ul':
            return (
                <ul {...(element.class ? {className: consumeClassNames(element.class)} : {})} {...(element.style ? {style: element.style} : {})} key={randomKey()}>
                    {element.content.map((item, index) => {
                        return <li key={randomKey()}>{item.text != null ? item.text : item.content.map((item, index) => renderJsonElement(item))}</li>
                    })}
                </ul>
            )
        case 'ol':
            return (
                <ol {...(element.class ? {className: consumeClassNames(element.class)} : {})} {...(element.style ? {style: element.style} : {})} key={randomKey()}>
                    {element.content.map((item, index) => {
                        return <li key={randomKey()}>{item.text != null ? item.text : item.content.map((item, index) => renderJsonElement(item))}</li>
                    })}
                </ol>
            )
        case 'table':
            return (
                <table {...(element.class ? {className: consumeClassNames(element.class)} : {})} {...(element.style ? {style: element.style} : {})} key={randomKey()}>
                    <thead>
                        <tr key={randomKey()}>
                        { element.headers.map((header, hindex) => (
                            <th key={randomKey()}>{typeof(header) === 'string' ? header : renderJsonElement(header)}</th>
                        ))}
                        </tr>
                    </thead>
                    <tbody>
                        { element.content.map((row, rindex) => (
                            <tr key={rindex}>
                                { row.map((column, cindex) => (
                                    <td key={cindex}>{typeof(column) === 'string' ? column : renderJsonElement(column)}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )
        default:

            // Custom Elements
            switch (element.type) {

                case 'x:Argument':
                    return (
                        <Argument {...(element.style ? {style: element.style} : {})} key={randomKey()} name={element['x:title']} type={element['x:type']} description={element['x:desc']} {...(element['x:required'] ? {required: true} : {})}/>
                    )

                case 'x:EndpointUrl':
                    return (
                        <EndpointURL {...(element.style ? {style: element.style} : {})} key={randomKey()}  method={element['x:method']}url={element['x:url']}/>
                    )

                case 'x:Code':
                    const langs = element['x:content'];

                    if (langs.length === 1 && langs[0].lang === 'json')
                        return (
                            <ResponseCodeBlock {...(element.style ? {style: element.style} : {})} key={randomKey()} language={'json'} code={langs[0].code}/>
                        )
                    else
                        return (
                            <CodeExample {...(element.style ? {style: element.style} : {})} key={randomKey()} examples={langs.map((item, index) => ({
                                lang: item.lang,
                                label: {
                                    'json': 'JSON', 
                                    'node': 'NodeJS', 
                                    'py': 'Python', 
                                    'csharp': 'C#',
                                    'bash': 'CURL'
                                }[item.lang],
                                code: item.code
                            }))}/>
                    )

                default:
                    console.log(`No rendering candidate found for: ${element.type}`);

            }
    }
}

export const Base = (props) => {

    const {page} = useParams()

    const dialog = useStore((state) => state.dialog);
    const setDialog = useStore((state) => state.setDialog);
    const close = useStore((state) => state.closeDialog);
    const [content, setContent] = useState(null);

    const contentRef = useCallback(node => {
        if (node !== null) document.body.setAttribute('style', `--window-height: ${window.innerHeight}px; --scrollbar-width: ${node.offsetWidth - node.clientWidth}px!important;`);
    }, []);

    useEffect(() => {
        fetch(`/oauth/${page}?format=json`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json()).then(res => {
            setContent(res);
        });
    }, []);

    return (
        <div className={[styles.container].join(' ').trim()}>
            <Sidebar />
            <div className={styles.content} ref={contentRef}>
                {content ? content.sections.map((item, index) => {
                    return (
                        <section className={styles.section} key={randomKey()}>
                            <section className={[styles['left-panel'], index === 0 ? styles.first : index === content.sections.length - 1 ? styles.last : null].join(' ').trim()}>
                                {item.content.map((item, index) => {
                                    return (
                                        renderJsonElement(item)
                                    )
                                })}
                            </section>
                            <section className={[styles['right-panel'], index === 0 ? styles.first : index === content.sections.length - 1 ? styles.last : null].join(' ').trim()}>
                                {item.examples.map((item, index) => {
                                    return (
                                        renderJsonElement(item)
                                    )
                                })}
                            </section>
                        </section>
                    )
                }) : (
                    <section className={styles.section} key={randomKey()}>
                        <section className={[styles['left-panel'], styles.loading].join(' ').trim()}></section>
                        <section className={[styles['right-panel'], styles.loading].join(' ').trim()}></section>
                    </section>
                )}
            </div>
            <Profile className={styles.profile} />
            <TransitionGroup onClick={(e) => {
                if (e.currentTarget !== e.target) return;
                (close ? close : setDialog(null))();
            }} className={[dialogStyles['dialog-wrapper'], dialog !== undefined && dialog !== null ? dialogStyles.visible : null].join(' ').trim()}>
                {dialog !== undefined && dialog !== null ? <CSSTransition appear timeout={300} classNames={{
                    enter: dialogStyles['transition-enter'],
                    enterActive: dialogStyles['transition-enter-active'],
                    exit: dialogStyles['transition-exit'],
                    exitActive: dialogStyles['transition-exit-active']
                }}>
                    {dialog}
                </CSSTransition> : null}
            </TransitionGroup>
        </div>
    )

}

export default Base;