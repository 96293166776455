import styles from '../assets/css/codeexample.module.css';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atelierPlateauLight as syntaxTheme } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Copy from '../assets/svgs/Copy';
import { useState } from 'react';

export const CodeExample = ({ examples }) => {

    const [copiedTimeout, setCopiedTimeout] = useState(null);
    const [selected, setSelected] = useState(0);

    return <>
        <div className={styles.container}>
            <div className={styles.header}>
                { examples.map((item, index) => {
                    return <div key={index} className={[styles.item, selected === index ? styles.selected : null].join(' ').trim()} onClick={() => setSelected(index)}>{item.label}</div>
                })}
            </div>
            <div className={styles.body}>
                { examples && Array.isArray(examples) && examples.length > 0 ? <>
                    { examples[selected] ? <>
                        <div className={styles.item}>
                            <div className={styles.copy} onClick={() => {
                                if (copiedTimeout) clearTimeout(copiedTimeout);
                                const timeout = setTimeout(() => {
                                    setCopiedTimeout(null);
                                }, 1500);
                                setCopiedTimeout(timeout);
                            }}>
                                <Copy/>
                                <div className={styles.tooltip}>{copiedTimeout ? 'Copied to Clipboard' : 'Copy to Clipboard'}</div>
                            </div>
                            <SyntaxHighlighter language={examples[selected].language} style={syntaxTheme} showLineNumbers={true} lineNumberStyle={{
                                textAlign: 'center',
                                color: 'var(--text-tertiary)'
                            }}>
                            {examples[selected].code}
                            </SyntaxHighlighter>
                    </div>
                    </> : null}
                </> : <>
                
                </>}
            </div>
        </div>
    </>

}

export default CodeExample;