import { useRoutes } from "react-router-dom";

import { Base as ArticleBase } from '../views/Article';
import { Base as Renderer } from '../views/article/Article';
import { Portal } from "../views/Portal";

export const Router = (props) => {
    const router = useRoutes([
        {
            element: <Portal/>,
            path: '/',
        },
        {
            element: <Renderer/>,
            path: '/oauth/:page',
        }
    ]);
    return router;
}
