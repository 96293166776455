import styles from '../assets/css/argument.module.css';

export const Argument = ({ name, type, required, description }) => {

    return <>
        <div className={styles.container}>
            <div className={styles.details}>
                <div className={styles.title}>
                    <span className={styles.name}>{name}</span>
                    <div className={styles.divider}></div>
                    <span className={styles.type}>{type}</span>
                </div>
                <div className={styles.subtitle}>{description}</div>
            </div>
            { required ? <div className={styles.required}></div> : null}
        </div>
    </>

}

export default Argument;