export default function Branch({ isLast, stroke, className, style }) {

    return (<>
        {isLast ? <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 0 6.5 16" stroke={stroke} className={className} style={style}>
                <path d="M 0 0 V 5 Q 0 8 3 8 H 4 M 0 5" />
            </svg>
        </> : <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 0 6.5 16" stroke={stroke} className={className} style={style}>
                <path d="M 0 0 V 5 Q 0 8 3 8 H 4 M 0 5 V 16" />
            </svg>
        </>}

    </>)

}