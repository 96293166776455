import styles from '../assets/css/endpointurl.module.css';

export const EndpointURL = ({ method, url }) => {

    return <>
        <div className={styles.container}>
            <div className={styles.method}>{method}</div>
            <div className={styles.endpoint}>{url}</div>
        </div>
    </>

}

export default EndpointURL;