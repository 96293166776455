import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../assets/css/sidebar.module.css'
import Caret from '../assets/svgs/Caret';
import Burger from './Burger';
import Search from '../assets/svgs/Search';
import Branch from '../assets/svgs/Branch';
import Plus from '../assets/svgs/Plus';

export const Sidebar = ({ editable, onChange, sidebarItems = [
    {
        label: 'Sign-In with Xenfuma',
        items: [
            { label: 'Getting Started' },
            {
                label: 'Authentication Flow',
                items: [
                    { label: 'Authorization Code Grant' },
                    { label: 'Implicit Grant' },
                    { label: 'ROPCG' },
                    { label: 'Client Credentials Grant' },
                    { label: 'Refresh Token' },
                ]
            },
            { label: 'Scopes and Permissions' },
            {
                label: 'Endpoints and URLs',
                items: [
                    { label: 'Authorization Endpoint' },
                    { label: 'Token Endpoint' },
                    { label: 'Refresh Token Endpoint' },
                    { label: 'User Info Endpoint' },
                    { label: 'Revocation Endpoint' },
                ]
            },
            { label: 'Error Handling' },
            { label: 'Security Best Practices' },
            { label: 'Integration Guides' },
            { label: 'Troubleshooting' },
        ]
    }
] }) => {

    const location = useLocation();
    const [menuActive, setMenuActive] = useState(false);
    const [items, setItems] = useState(sidebarItems);

    useEffect(() => {

        setMenuActive(false);

    }, [location])

    return (
        <div className={[styles.sidebar, menuActive ? styles.active : null, editable ? styles.editable : null].join(' ').trim()}>
            <header className={styles.header}>
                <img className={styles.logo} alt={""} src={"https://accounts.xenfuma.com/filesystem/svg/xenfuma_logo_b.svg"} />
                <div className={styles.title}><b>{"Xenfuma"}</b>{" Docs"}</div>
                <Burger className={styles.burger} active={menuActive} onToggle={setMenuActive} />
            </header>
            <nav className={styles.navigation}>
                <div className={styles.search}>
                    <div className={styles['icon-wrapper']}>
                        <Search />
                    </div>
                    <input placeholder={'Search Docs'} />
                </div>
                <div className={styles.content}>
                    <ul className={styles.categories}>
                        {items.map((item, index) => {
                            return <Category key={index} label={item.label} editable={editable} onDelete={() => {
                                const copy = Array.from(items);
                                copy.splice(index, 1);
                                setItems(copy);
                            }} onAdd={() => {
                                const copy = Array.from(items);
                                copy[index].items.push({
                                    label: 'New Item'
                                });
                                setItems(copy);
                            }} onNameChange={(name) => {
                                const copy = Array.from(items);
                                copy[index].label = name;
                                setItems(copy);
                            }}>
                                {(item.items ? item.items : []).map((_item, _index) => {
                                    return _item.items ? <Item key={_index} label={_item.label} editable={editable} onDelete={() => {
                                        const copy = Array.from(items);
                                        copy[index].items.splice(_index, 1);
                                        setItems(copy);
                                    }} onAdd={() => {
                                        const copy = Array.from(items);
                                        copy[index].items[_index].items.push({
                                            label: 'New Item'
                                        });
                                        setItems(copy);
                                    }} onNameChange={(name) => {
                                        const copy = Array.from(items);
                                        copy[index].items[_index].label = name;
                                        setItems(copy);
                                    }}>
                                        {_item.items.map((__item, __index) => {
                                            return <Item key={__index} label={__item.label} editable={editable} onClick={__item.onClick} onDelete={() => {
                                                const copy = Array.from(items);
                                                if (copy[index].items[_index].items.length > 1)
                                                    copy[index].items[_index].items.splice(__index, 1);
                                                else
                                                    copy[index].items[_index].items = undefined;
                                                setItems(copy);
                                            }} onNameChange={(name) => {
                                                const copy = Array.from(items);
                                                copy[index].items[_index].items[__index].label = name;
                                                setItems(copy);
                                            }} />
                                        })}
                                    </Item> :
                                        <Item label={_item.label} key={_index} editable={editable} onClick={_item.onClick} onDelete={() => {
                                            const copy = Array.from(items);
                                            copy[index].items.splice(_index, 1);
                                            setItems(copy);
                                        }} onAdd={() => {
                                            const copy = Array.from(items);
                                            copy[index].items[_index] = {
                                                label: _item.label,
                                                items: [
                                                    {
                                                        label: 'New Item'
                                                    }
                                                ]
                                            }
                                            setItems(copy);
                                        }} onNameChange={(name) => {
                                            const copy = Array.from(items);
                                            copy[index].items[_index].label = name;
                                            setItems(copy);
                                        }} />
                                })}
                            </Category>
                        })}
                        {editable ? <>
                            <Category label={'Editor'}>
                                <Item label={'New Category'} onClick={() => {
                                    console.log('WHAT')
                                    setItems([
                                        ...items,
                                        {
                                            label: 'New Category',
                                            items: []
                                        }
                                    ])
                                }} />
                            </Category>
                        </> : null}
                        <Category className={styles.account} label={'Account'}>
                            <Item label={'Manage Account'}/>
                            <Item label={'Developer Console'}/>
                            <Item label={'Logout'}/>
                        </Category>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export const Category = ({ label, children, editable, onDelete, onAdd, onNameChange, className }) => {

    const [_label] = useState(label);

    return <>
        <li className={[styles.category, className].join(' ').trim()}>
            <div className={[styles.categoryHeader, editable ? styles.editable : null].join(' ').trim()}>
                <span suppressContentEditableWarning={true} className={styles.label} {...(editable ? { contentEditable: true, spellCheck: false } : {})} onInput={(event) => {
                    (onNameChange ? onNameChange : () => { })(event.nativeEvent.target.innerText);
                }}>{_label}</span>
                {editable ? <>
                    <div className={styles.editorButtons}>
                        <div className={styles.delete} onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            (onDelete ? onDelete : () => { })();
                        }}>
                            <Plus />
                        </div>
                        <div className={styles.add} onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            (onAdd ? onAdd : () => { })();
                        }}>
                            <Plus />
                        </div>
                    </div>
                </> : null}
            </div>
            <ul className={styles.items}>
                {children}
            </ul>
        </li>
    </>
}

export const Item = ({ label, children, isSublistItem, isLast, editable, onDelete, onClick, onAdd, onNameChange }) => {

    const [_label] = useState(label);

    const [expanded, setExpanded] = useState(true);
    const sublistItems = React.Children.map(children, child => {
        if (React.isValidElement(child))
            return React.cloneElement(child, { isSublistItem: true, editable: editable, isLast: (Array.isArray(children) ? (children.indexOf(child) === children.length - 1) : true) });
        return child;
    })
    return <>
        <li className={[children ? styles.expandable : null, expanded ? styles.expanded : null, isSublistItem ? styles.sublistItem : null, editable ? styles.editable : null].join(' ').trim()}>
            <div className={[styles.item, styles.expanded, editable ? styles.editable : null].join(' ').trim()} onClick={children ? () => {
                setExpanded(!expanded);
            } : onClick ? onClick : () => {}}>
                <span suppressContentEditableWarning={true} {...(editable ? { contentEditable: true, spellCheck: false } : {})} onInput={(event) => {
                    (onNameChange ? onNameChange : () => { })(event.nativeEvent.target.innerText);
                }}>{_label}</span>
                {children ? <Caret className={styles.expand} /> : null}
                {editable ? <>
                    <div className={styles.editorButtons}>
                        <div className={styles.delete} onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            (onDelete ? onDelete : () => { })();
                        }}>
                            <Plus />
                        </div>
                        <div className={styles.add} onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setExpanded(true);
                            (onAdd ? onAdd : () => { })();
                        }}>
                            <Plus />
                        </div>
                    </div>
                </> : null}
            </div>
            {children && expanded ? <>
                <ul className={styles.sublist}>
                    {sublistItems}
                </ul>
            </> : null}
            {isSublistItem ? <>
                <Branch className={styles.branch} isLast={isLast} />
            </> : null}
        </li>
    </>
}

export default Sidebar;