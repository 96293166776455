import { useEffect, useRef } from "react";
import Config from '../config.json';
import { useStore } from "../assets/js/Store";

export const IdentityProvider = ({ onLoad }) => {

    const iframeRef = useRef(null);
    const setAuthenticated = useStore((state) => state.setAuthenticated);

    useEffect(() => {
        const onMessage = (event) => {
            if (event.data.session_id !== undefined && event.data.current !== undefined) {
                setAuthenticated(true);
                const sessionId = event.data.session_id;
                const current = event.data.current;
                localStorage.setItem('session_id', sessionId);
                localStorage.setItem('current', current);
                (onLoad ? onLoad : () => {})({
                    session: sessionId,
                    current: current
                });
            }
        }
        window.addEventListener('message', onMessage);
        return () => window.removeEventListener('message', onMessage)
    }, [setAuthenticated, onLoad]);

    return <iframe 
        ref={iframeRef}
        title={'identity-provider'}
        style={{display: 'none'}} 
        id={'identity-provider'} 
        onLoad={() => iframeRef.current.contentWindow.postMessage('identify', Config.API_ENDPOINT)}
        src={`${Config.API_ENDPOINT}/api/v1/identify`}></iframe>

}

export default IdentityProvider;