import { useEffect, useState } from 'react';
import styles from '../assets/css/buttons.module.css';
import Loader from './Loader';

export const PrimaryButton = (props) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(props.loading);

    }, [props.loading]);

    return <button type="button" className={[props.className, styles.button, loading ? styles.loading : null].join(' ').trim()} onClick={(event) => {
        (props.onClick ? props.onClick : () => {})({
            initialEvent : event,
            setLoading   : setLoading
        })
    }}>
        <div className={styles.contents}>
            {loading ? <>
                <Loader className={styles.loading}/>
            </> : null}
            <div className={styles.label}>{props.label}</div>
        </div>
    </button>

}

export const SecondaryButton = (props) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(props.loading);

    }, [props.loading]);

    return <button type="button" className={[props.className, styles.button, styles.secondary, loading ? styles.loading : null].join(' ').trim()} onClick={(event) => {
        (props.onClick ? props.onClick : () => {})({
            initialEvent : event,
            setLoading   : setLoading
        })
    }}>
        <div className={styles.contents}>
        {loading ? <>
                <Loader className={styles.loading}/>
            </> : null}
            <div className={styles.label}>{props.label}</div>
        </div>
    </button>

}

export const DangerButton = (props) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(props.loading);

    }, [props.loading]);

    return <button type="button" className={[props.className, styles.button, styles.danger, loading ? styles.loading : null].join(' ').trim()} onClick={(event) => {
        (props.onClick ? props.onClick : () => {})({
            initialEvent : event,
            setLoading   : setLoading
        })
    }}>
        <div className={styles.contents}>
        {loading ? <>
                <Loader className={styles.loading}/>
            </> : null}
            <div className={styles.label}>{props.label}</div>
        </div>
    </button>

}