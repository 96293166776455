import { create } from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';

export const useStore = create((set) => ({

    authenticated: false,
    setAuthenticated: ( authenticated ) => set({ authenticated: authenticated }),

    identity: null,
    setIdentity: ( identity ) => set({ identity: identity }),

    session: null,
    setSession: ( session ) => set({ session: session }),

    sessionId: undefined,
    setSessionId: ( sessionId ) => set({ sessionId: sessionId }),

    current: undefined,
    setCurrent: ( current ) => set({ current: current }),

    dialog: null,
    closeDialog: null,
    setDialog: ( dialog ) => set({ dialog: dialog }),
    setCloseDialog: ( closeDialog ) => set({ closeDialog: closeDialog }),

}))

if (process.env.NODE_ENV === 'development') { mountStoreDevtool('Store', useStore); }
